var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"formulario-card",attrs:{"flat":""}},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(" Cadastro ")])]),(_vm.user != undefined)?_c('div',{staticClass:"conteudo"},[_c('v-form',{ref:"form",staticClass:"formulario",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"nome"},[_c('span',[_vm._v(" Nome completo")]),_c('v-text-field',{staticClass:"input",attrs:{"placeholder":"Joilson da Silva Lima","rules":[(v) => !!v || 'Por favor, insira o seu nome'],"color":"secondary","required":"","dense":"","outlined":"","rounded":""},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('div',{staticClass:"email"},[_c('span',[_vm._v("E-mail")]),_c('v-text-field',{staticClass:"input",attrs:{"placeholder":"nome@email.com","rules":[(v) => !!v || 'Por favor, insira o seu e-mail'],"color":"secondary","required":"","dense":"","outlined":"","rounded":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('div',{staticClass:"documento-celular-container"},[_c('div',{staticClass:"select-document"},[_c('div',{staticClass:"select"},[_c('span',[_vm._v("Documento")]),_c('v-select',{staticClass:"select",attrs:{"items":_vm.items,"item-text":"name","menu-props":{ bottom: true, offsetY: true },"placeholder":"Selecione","color":"secondary","required":"","outlined":"","rounded":"","dense":""},model:{value:(_vm.documentType),callback:function ($$v) {_vm.documentType=$$v},expression:"documentType"}})],1),_c('div',{staticClass:"field"},[(_vm.documentType == 'CPF')?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##']),expression:"['###.###.###-##']"}],staticClass:"input",attrs:{"placeholder":"000.000.00-00","rules":[
                (v) => !!v || 'Por favor, insira seu documento',
                _vm.rules.documentMinCpf,
              ],"color":"secondary","required":"","outlined":"","dense":"","rounded":""},model:{value:(_vm.user.document),callback:function ($$v) {_vm.$set(_vm.user, "document", $$v)},expression:"user.document"}}):_vm._e(),(_vm.documentType == 'CNPJ')?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['##.###.###/####-##']),expression:"['##.###.###/####-##']"}],staticClass:"input",attrs:{"placeholder":"00.000.000/0000-00","rules":[
                (v) => !!v || 'Por favor, insira seu documento',
                _vm.rules.documentMinCNPJ,
              ],"color":"secondary","required":"","outlined":"","dense":"","rounded":""},model:{value:(_vm.user.document),callback:function ($$v) {_vm.$set(_vm.user, "document", $$v)},expression:"user.document"}}):_vm._e()],1)]),_c('div',{staticClass:"celular"},[_c('span',[_vm._v("Celular")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['(##)####-####', '(##)#####-####']),expression:"['(##)####-####', '(##)#####-####']"}],staticClass:"input",attrs:{"placeholder":"(00) 0 0000-0000","rules":[(v) => !!v || 'Por favor, insira seu celular'],"color":"secondary","required":"","outlined":"","rounded":"","dense":""},model:{value:(_vm.user.phoneNumber),callback:function ($$v) {_vm.$set(_vm.user, "phoneNumber", $$v)},expression:"user.phoneNumber"}})],1)]),_c('div',{staticClass:"senha"},[_c('span',{staticClass:"btn-alterar-senha",on:{"click":_vm.goAlterarSenha}},[_vm._v(" Trocar senha ")])])]),_c('div',{staticClass:"img"},[_c('figure',[_c('img',{staticClass:"profile-img",attrs:{"src":_vm.imgProfile,"alt":"imagem de perfil"}})]),(!_vm.changeImagemPerfil)?_c('label',[_c('input',{attrs:{"type":"file","name":"fileImage","accept":"image/png","id":"input-file"},on:{"change":_vm.pegarImagem}}),_vm._v(" Trocar imagem de perfil ")]):_vm._e(),(_vm.changeImagemPerfil)?_c('label',{on:{"click":_vm.cancelChangeImagemPerfil}},[_vm._v(" Cancelar ")]):_vm._e()])],1):_vm._e(),_c('div',{staticClass:"btn"},[_c('v-btn',{staticClass:"botao-atualizar",attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.atualizarPerfil}},[_vm._v(" atualizar ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }